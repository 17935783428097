@font-face {
  font-family: 'Petrona';
  src: url(../fonts/Petrona-Regular.ttf);
}
* {
  margin: 0px;
  padding: 0px;
}
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: none;
  font-family: 'Petrona', serif;
  line-height: 1.58;
  letter-spacing: -.004em;
  #root {
    width: 100%;
    height: 100%;
    .column {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &.main-content {
        background: #404258;
        color: #f8f8f8;
      }
    }
    @media only screen and (max-width: 800px) {
      .column { 
        float: none;
        width:  100%;
        height: auto;
        overflow-y: visible;
      }
    }
    @media only screen and (min-width: 801px) {
      .column {
        float: left;
        width:  50%;
      }
    }
  }
}
