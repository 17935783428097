.navbar {
  text-align: center;
  top: 0;
  margin: 0 auto;
  width: 100%;
  margin-top: 0px;
  background: #50577a;
  z-index: 200;
  &.sticky {
    position: fixed;
  }
  a {
    background: transparent;
    text-decoration: none; 
    font-family: inherit;
    color: white;
    width: 33%;
    font-size: 120%;
    padding: 15px 0px 15px 0px;
    display: inline-block;
    border: 0px;
    border-bottom: 4px solid #6b728e;
    border-right: 1px solid #6b728e;
    transition: 0.3s;
    cursor: pointer;
    &.active, &:hover, &:focus {
      outline: 0;
      border-bottom: 4px solid #a5b1c2;
      color: #eeeeee;
    }
    &.active {
      pointer-events: none;
      cursor: default;
    }
    &:last-child {
      border-right: 0px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .padding {
    height: 0px;
    &.sticky {
      height: 50px;
    }
  }
}
@media only screen and (min-width: 800px) {
  .navbar { 
    position: fixed;
    width: inherit;
  }
  .padding {
    height: 50px;
  }
}