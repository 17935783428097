.intro {
  height: auto;
  min-height: 100%;
  color: white;
  background: #353b48;
  font-size: 200%;
  text-align:center; 
  .content {
    max-width: 600px;
    margin: 0 auto;
    padding: 10px;
    padding-top: 15%;
    .logo {
      width: 40%;
    }
    h1 {
      line-height: 1.2;
      padding-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
    }
    .btn-down {
      display: none;
      opacity: 0.6;
      cursor: pointer;
      background: transparent;
      padding: 15px;
      color: white;
      border: none;
      margin: 0 auto;
      font-size: 120%;
      margin-top: 5%;
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
      &:hover, &:focus {
        opacity: 1;
        outline: 0;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .intro {
    .content {
      padding: 12% 5% 5% 5%;
      overflow: visible;
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
      .logo {
        font-size: 450%;
      }
      .btn-down {
        display: block;
      }
    }
  }
}
@media only screen and (min-width: 801px) {
  .intro {
    .content {
      .btn-down {
        display: none;
      }
    }
  }
}