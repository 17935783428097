.experiences {
  width: 100%;
  display: flex;
  flex-direction: column;
  .experiences-list {
    width: 70%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    .experience-item {
      padding: 10px 0px 10px 0px;
      .title {
        font-size: 120%;
      }
      ul {
        padding-left: 20px;
      }
    }    
    svg {
      font-size: 600%;
      text-align: center;
      display: block;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 20px;
      border-radius: 3px;
      color: #a5b1c2;
      opacity: 0.9;      
    }
    hr {
      margin: 20px 0px 20px 0px;
      border: 0;
      height: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }  
  }
}
@media only screen and (min-width: 801px) {
  .experiences {
    float: left;
  }
}
